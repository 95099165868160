import $ from 'jquery';
import { Modal } from 'bootstrap';

$(function () {
    const $loginModal = new Modal(document.querySelector('#login-modal'));
    const $registrationModal = new Modal(document.querySelector('#registration-modal'));
    const $registrationModalSuccess = new Modal(document.querySelector('#registration-modal-success'));

    $('.app-show-login-modal').on('click', function (e) {
        e.preventDefault();
        $loginModal.show();
    })

    $('.app-show-registration-modal').on('click', function (e) {
        e.preventDefault();
        $loginModal.hide();
        $registrationModal.show();
    });

    $('.app-user-logout').on('click', function (e) {
        e.preventDefault();
        $.request('onCabinetUserLogOut', {
            method: 'POST'
        })
        .then(() => {
            location.href = '/';
        });
    });

    window.onRegistrationSuccess = function () {
        $registrationModal.hide();
        $registrationModalSuccess.show();
    }
});
