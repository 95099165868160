import { Swiper, Navigation, Pagination } from "swiper";
import $ from 'jquery';

$(function () {
    const $currentYear = $('.app-main-portfolio-current-year');
    const $prevYear = $('.app-main-portfolio-prev-year');

    // слайдер в просмотре портфолио (кейса)
    new Swiper('.swiper-cards-slider', {
        modules: [Navigation, Pagination],
        slidesPerView: 4,
        spaceBetween: 60,
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 60,
              },
            640: {
              slidesPerView: 2,
              spaceBetween: 60,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 60,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 60,
            },
          },
        navigation: {
            nextEl: '.swiper-cards-slider__next',
            prevEl: '.swiper-cards-slider__prev',
        }
    });

    new Swiper('.swiper-completed-work', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 60,
        navigation: {
            nextEl: '.swiper-completed-work-next',
            prevEl: '.swiper-completed-work-prev',
        },
        on: {
            /**
             * Мы подписались на это событие, т.к. нам нужно поменять года в портфолио
             * @param swiper
             */
            slideChange(swiper) {
                const slide = swiper.slides[swiper.realIndex];
                const $slide = $(slide);
                $currentYear.html($slide.data('year'));
                $prevYear.html($slide.data('prev-year'));
            }
        }
    });
});