import $ from 'jquery';

$(function () {
    const $closeWiki = $('.app-close-wiki');
    const $showWiki = $('.app-show-wiki');
    const $cabinetWrapper = $('.app-cabinet-wrapper');

    $closeWiki.on('click', function (e) {
        e.preventDefault();
        $(this).addClass('d-none');
        $showWiki.removeClass('d-none');
        $cabinetWrapper.addClass('wiki-close');
    });

    $showWiki.on('click', function (e) {
        e.preventDefault();
        $(this).addClass('d-none');
        $closeWiki.removeClass('d-none');
        $cabinetWrapper.removeClass('wiki-close');
    });
})