import './utils/scrolling';
import './utils/sliders';
import './utils/menu';
import './utils/news-view';
import './utils/cabinet';
import './utils/wiki';
import $ from 'jquery';
import Inputmask from "inputmask";

window.$ = $;
window.jQuery = $;

import { Modal } from 'bootstrap';

require('@fancyapps/fancybox');

$(function () {
    const mask = new Inputmask('+7 (999) 999-99-99');
    mask.mask(document.querySelectorAll('.maskedinput-phone'));
})


// $(function() {
//     $(".licensenumber").off('click', function() {
//             var nametitle = $(this).attr('data-nametitle');
//
//             $(".licensenum").append( nametitle );
//         })
// });


const modalPrice = document.getElementById('modalPrice')
const modalLicense = document.getElementById('modalLicense')
if (modalPrice) {
    modalPrice.addEventListener('show.bs.modal', event => {

        const button = event.relatedTarget

        const recipient = button.getAttribute('data-nametitle')

        const modalTitle = modalPrice.querySelector('.licensenum')

        modalTitle.textContent = recipient
    })
}
if (modalLicense) {
    modalLicense.addEventListener('show.bs.modal', event => {

        const button = event.relatedTarget

        const recipient = button.getAttribute('data-nametitle')

        const modalTitle = modalLicense.querySelector('.licensenum')

        modalTitle.textContent = recipient
    })
}

const modalText = document.getElementById('modalPrice')
const modalText2 = document.getElementById('modalLicense')
if (modalText) {
    modalText.addEventListener('show.bs.modal', event => {

        const button = event.relatedTarget

        const recipient = button.getAttribute('data-text')

        const modalTitle = modalText.querySelector('.licensetext')

        modalTitle.textContent = recipient
    })
}
if (modalText2) {
    modalText2.addEventListener('show.bs.modal', event => {

        const button = event.relatedTarget

        const recipient = button.getAttribute('data-text')

        const modalTitle = modalText2.querySelector('.licensetext')

        modalTitle.textContent = recipient
    })
}